import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import styled, { useTheme, css } from 'styled-components'

export const makeStyles = (options: any) => {
  const useStyles = () => {
    const theme = useTheme()
    const [current, setCurrent] = useState<any>()

    useEffect(() => {
      const current = { theme }
      setCurrent(current)
    }, [theme])

    const classes = { ...current, ...options(theme) }

    return classes
  }

  return useStyles
}

const sizes = {
  sm: '576px',
  md: '767px',
  lg: '992px',
  xl: '1366px',
  xxl: '1650px'
}

export const device = (size: 'sm' | 'md' | 'lg' | 'xl' | string) => {
  const mediaRegex = /(d*\.?\d+)(px|em|%|rem|vw|pt+)/g.test(size)
  const breakpoint = Object.keys(sizes).includes(size)

  if (!mediaRegex && !breakpoint) {
    console.error(`Error: Media query ${size} not supported`)
  }

  const media = Object.keys(sizes).includes(size) ? sizes[size] : size
  return `@media (min-width: ${media})`
}

export const hexToRGB = (hex: string, alpha: string) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}

export const Separator = styled.span(({ theme, showNewColor }) => ({
  width: 2,
  height: 40,
  left: '48%',
  position: 'absolute',
  background: showNewColor ? theme.color.blue4 : theme.color.purple4
}))

export const Dots = (bgcolor: string, top: string | number) => ({
  width: 12,
  height: 12,
  top: top || 0,
  left: -18,
  position: 'absolute',
  borderRadius: '50%',
  background: bgcolor
})

export const LineDashed = (color: string, top: string | number) => ({
  top: top || 0,
  left: -13,
  height: '100%',
  position: 'absolute',
  borderLeftStyle: 'dashed',
  borderLeftWidth: 1,
  borderLeftColor: color
})

export const StyleFlexBetween = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}

export const StylesScrollBar = ({
  theme,
  bgcolor
}: {
  theme: any
  bgcolor?: string
}) => ({
  '::-webkit-scrollbar': {
    background: bgcolor || theme.color.gray6,
    width: 8,
    height: 8
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.color.blue4,
    borderRadius: 4
  },
  // Styles for firefox
  scrollbarColor: `${theme.color.blue4} ${bgcolor || theme.color.gray6}`,
  scrollbarWidth: 'thin'
})

export const StyleFlexStart = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
}

export const SizeCurrency = (size?: number | string, mr?: number | string) => ({
  fontSize: size || 14,
  marginRight: mr || 3
})

export const Capitalize = {
  textTransform: 'capitalize'
}

export const Content = styled.div(() => ({
  display: 'inline',
  whiteSpace: 'nowrap'
}))

export const AppWrapper = styled.main(({ isDoku }) => ({
  padding: 0,
  flex: 1,
  maxWidth: 1650,
  width: '100%',
  height: '90%',
  boxSizing: 'border-box',
  [isDoku && css]: {
    height: 'auto'
  },
  [device('xxl')]: {
    margin: '0 auto'
  }
}))

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ mt }) => mt || 0};
  margin-bottom: ${({ mb }) => mb || 0};
`
export const FlexStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Amount = styled.p(({ fontSize, right }) => ({
  margin: 0,
  padding: 0,
  '&::first-letter': {
    fontSize: fontSize || 14,
    marginRight: right || 3
  }
}))

export const FieldWrapper = styled.div.attrs(
  ({
    right = 0,
    left = 0,
    bottom = '2.5em',
    width = '100%',
    direction = 'column'
  }) => ({
    right,
    left,
    width,
    bottom,
    direction
  })
)`
  width: ${props => props.width};
  position: relative;
  display: flex;
  margin-bottom: ${props => props.bottom};
  flex-direction: ${props => props.direction};
  margin-right: ${props => props.right};
  margin-left: ${props => props.left};
`
export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1.2em 0;
`

export const WrapperTable = styled.div(({ theme, height }) => ({
  borderRadius: 16,
  padding: '1em',
  background: theme.color.gray6,
  height: height || '82%',
  overflow: 'auto',
  ...StylesScrollBar({ theme, bgcolor: 'transparent' })
}))

export const Table = styled.table`
  width: 100%;
  margin-bottom: 0.7rem;
  border-collapse: separate;
  border-spacing: 0 0.175em;
`

export const TableHeader = styled.thead(({ theme }) => ({
  background: theme.color.gray6,
  '& > tr': {
    textAlign: 'left',
    boxShadow: `0px 2px 0px 0px ${theme.hexToRGB(theme.color.gray3, '0.75')}`,
    '& > th': {
      padding: '1.5rem 1rem'
    }
  }
}))

export const TableBody = styled.tbody(({ theme, middle, withoutShadow }) => ({
  background: theme.color.gray6,
  '& > tr': {
    cursor: 'pointer',
    textAlign: 'left',
    boxShadow: `0px 1px 0px 0px ${theme.hexToRGB(theme.color.gray4, '0.75')}`,
    [withoutShadow && css]: {
      boxShadow: 'none'
    },
    '&:hover': {
      background: theme.color.gray5
    },
    '& > td': {
      fontSize: 16,
      letterSpacing: 0,
      padding: '1.5rem 1rem',
      verticalAlign: 'baseline',
      [middle && css]: {
        verticalAlign: 'middle'
      },
      '& > a': {
        color: theme.color.blue3
      }
    }
  },
  '& > tr:last-child': {
    boxShadow: 'none'
  },
  '.size': {
    width: 100
  }
}))

export const TableRow = styled.p(({ theme }) => ({
  fontSize: 16,
  letterSpacing: 0,
  padding: 0,
  margin: 0,
  color: theme.color.purple2,
  fontWeight: theme.weight.medium
}))

export const TableCell = styled.p(
  ({ theme, amount, noWhiteSpace, color, textTransform }) => ({
    fontSize: 16,
    letterSpacing: 0,
    margin: 0,
    padding: 0,
    width: '100%',
    textTransform: textTransform || 'capitalize',
    color: theme.color.blue1,
    fontWeight: theme.weight.regular,
    '::first-letter': {
      [amount && css]: {
        ...theme.sizeCurrency()
      }
    },
    [noWhiteSpace && css]: {
      whiteSpace: 'nowrap'
    },
    [color && css]: {
      color: color
    }
  })
)

export const PositionSticky = styled.div(({ theme, bgcolor, top, pt, mt }) => ({
  position: 'sticky',
  zIndex: 2,
  paddingTop: pt || 10,
  top: top || 0,
  marginTop: mt || 0,
  background: bgcolor || theme.color.gray6
}))

export const Wrapper = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  padding: 20,
  width: '100%',
  position: 'relative',
  height: '100%',
  [theme.device('lg')]: {
    padding: '40px 30px 30px 60px'
  }
}))

export const Container = styled.div(({ theme, showTemplate }) => ({
  boxSizing: 'border-box',
  padding: '20px 20px 100px',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  [theme.device('lg')]: {
    padding: '40px 30px 30px 60px'
  },
  '& > div': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.device('md')]: {
      flexDirection: 'row'
    },
    '& > button.btn-editline': {
      alignSelf: 'flex-end',
      width: 160
    }
  },
  [showTemplate && css]: {
    overflow: 'initial'
  },
  '.downloadpdf:disabled[disabled]': {
    background: 'transparent'
  }
}))

export const RolesContainer = styled(motion.div)(({ theme }) => ({
  boxSizing: 'border-box',
  padding: 20,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  [theme.device('xl')]: {
    padding: '40px 40px 60px'
  }
}))

export const TextElipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}
